import React, { useState } from 'react';
import {
  Col, Card, CardHeader, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { AccountRequestStatus } from '@hedgecapitalmarkets/hedge-events';
import ReactTable from '../../Components/ReactTable/ReactTable';
import { getApexAccountDetails, updateAccountStatus } from '../../../Services/AccountServices';

function ApexAccountOverviewTable({ accountNumber }) {
  const queryClient = useQueryClient();
  const [accountOverviewTable, setAccountOverviewTable] = useState([]);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [newStatus, setNewStatus] = useState('');
  const {
    isLoading, isError, error,
    data,
  } = useQuery(
    ['getApexAccountDetails', accountNumber],
    () => getApexAccountDetails(accountNumber),
    {
      onSuccess: (response) => {
        const acctOverview = [
          {
            label: 'Account Type',
            value: response?.accountOverview?.accountType,
          },
          {
            label: 'Account Sub Type',
            value: response?.accountOverview?.accountSubType,
          },
          {
            label: 'Margin Type',
            value: response?.accountOverview?.marginType,
          },
          {
            label: 'Account Number',
            value: response?.accountOverview?.accountNumber,
          },
          {
            label: 'Maintenance Requirement',
            value: response?.accountOverview?.maintenanceRequirement,
          },
          {
            label: 'Opened On',
            value: response?.accountOverview?.openedOn,
          },
          {
            label: 'Apex Status',
            value: response?.accountOverview?.accountStatus,
          },
          {
            label: 'Apex Account Id',
            value: response?.accountOverview?.userAccountId,
          },
          {
            label: 'Hedge Status',
            value: response?.accountOverview?.hedgeAccountStatus,
            actions: (
              <div className="actions-right">
                <Button
                  type="button"
                  onClick={() => setShowStatusModal(true)}
                  className="ml-2 px-2 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Change Status
                </Button>
              </div>
            ),
          },
        ];
        setAccountOverviewTable(acctOverview);
      },
    },
  );

  const mutation = useMutation(updateAccountStatus, {
    onSuccess: () => {
      setShowStatusModal(false);
      if (data?.accountOverview?.userAccountId) {
        queryClient.invalidateQueries(['getApexAccountDetails']);
      }
    },
    onError: (e) => {
      alert(`Error updating status: ${e.message}`);
    },
  });

  const handleStatusSubmit = () => {
    console.log('newStatus', newStatus);
    if (newStatus) {
      mutation.mutate({ accountNumber: data?.accountOverview?.userAccountId, newStatus });
    } else {
      alert('Please select a status');
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return (
      <p>
        Error:
        {error?.message || 'Failed to load account details.'}
      </p>
    );
  }

  return (
    <>
      <Col xs={12} md={12}>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              Account Overview For:
              {' '}
              {accountNumber}
            </CardTitle>
          </CardHeader>
          <CardBody>
            {accountOverviewTable && accountOverviewTable.length > 0 ? (
              <ReactTable
                data={accountOverviewTable}
                filterable={false}
                resizable={false}
                columns={[
                  { Header: 'Label', accessor: 'label' },
                  { Header: 'Value', accessor: 'value' },
                  {
                    Header: 'Actions',
                    accessor: 'actions',
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            ) : (
              <span>loading...</span>
            )}
          </CardBody>
        </Card>
      </Col>

      {/* Modal for Changing Status */}
      <Modal isOpen={showStatusModal} toggle={() => setShowStatusModal(!showStatusModal)}>
        <ModalHeader toggle={() => setShowStatusModal(!showStatusModal)}>Change Account Status</ModalHeader>
        <ModalBody>
          <label htmlFor="statusSelect">Select New Status:</label>
          <select
            id="statusSelect"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
            className="form-control"
          >
            <option value="">-- Select Status --</option>
            {Object.values(AccountRequestStatus).map((status) => (
              <option key={status} value={status}>{status}</option>
            ))}
          </select>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleStatusSubmit}>
            Submit
          </Button>
          <Button color="secondary" onClick={() => setShowStatusModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ApexAccountOverviewTable;
