/* eslint-disable no-console */
import { APIRequestWithAuth } from './config';

class GroupInvestingService {
  static async searchInvestingGroups(query) {
    try {
      const response = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/groups/search?${query}`,
      );
      if (response) {
        if (response.data) {
          return {
            data: response.data,
            pages: response.pages || 1,
            totalCount: response.total || response.data.length,
          };
        }
      }

      return { data: [], pages: 0, totalCount: 0 };
    } catch (error) {
      console.error(error);
      return { data: [], pages: 0, totalCount: 0 };
    }
  }

  static async getGroupAum(groupId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/aum/${groupId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getGroupProposalsByGroupId(groupId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/proposals/${groupId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getGroupWithdrawalsByGroupId(groupId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/withdrawals/${groupId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getProposalById(proposalId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/proposal/${proposalId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getAccountStatusEvents(groupId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/events/memberAccount/${groupId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getMemberAccountsForGroup(groupId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/accounts/member/${groupId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getIndividualAccountsForGroup(groupId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/accounts/individual/${groupId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getAllAccountsForGroup(groupId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/accounts/all/${groupId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getOrderEventsForProposal(proposalId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/events/orders/${proposalId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getAllocationEventsForProposal(proposalId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/events/allocations/${proposalId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getGroupTransferEvents(proposalId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/events/transfers/${proposalId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getWithdrawalDetailsById(withdrawalId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/events/withdrawal/${withdrawalId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getWithdrawalItemsByWithdrawalId(withdrawalId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/events/withdrawalItems/all/${withdrawalId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getWithdrawalMasterEvents(withdrawalId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/events/master/${withdrawalId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static async getWithdrawalSubEvents(masterEventId) {
    try {
      const data = await APIRequestWithAuth(
        'GET',
        `/v1/groupInvesting/events/sub/${masterEventId}`,
      );
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}

export default GroupInvestingService;
